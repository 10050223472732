import Card, { CardThemeType } from '@UIKit/Card/Card';
import React, { Children, PropsWithChildren, ReactNode } from 'react';

type BentoLayoutProps = {
  className?: string;
};

export const BentoLayout = ({ className = '', ...props }: PropsWithChildren<BentoLayoutProps>) => {
  const children = Children.toArray(props.children);

  return (
    <div
      className={`${className} -m-3 flex w-full flex-wrap max-sm:w-[calc(100%+theme(spacing.6))]`}>
      {children}
    </div>
  );
};

const Column = (props: PropsWithChildren) => (
  <div className="flex w-full flex-1 flex-col content-start xs:min-w-[theme(spacing.96)]">
    {props.children}
  </div>
);
BentoLayout.Column = Column;

const OneThirdColumn = (props: PropsWithChildren) => (
  <div className="flex w-full flex-col content-start xs:w-1/3">{props.children}</div>
);
BentoLayout.OneThirdColumn = OneThirdColumn;

const TwoThirdsColumn = (props: PropsWithChildren) => (
  <div className="flex w-full flex-col content-start xs:w-2/3">{props.children}</div>
);
BentoLayout.TwoThirdsColumn = TwoThirdsColumn;

const FullRowCard = ({
  className = '',
  dataTestid = '',
  style,
  children,
  theme
}: {
  className?: string;
  dataTestid?: string;
  style?: React.CSSProperties;
  children: ReactNode;
  theme?: CardThemeType;
}) => (
  <Card
    style={style}
    theme={theme}
    className={`${className} m-3 min-h-52 w-[calc(100%-theme(spacing.6))]`}
    dataTestid={dataTestid}>
    {children}
  </Card>
);
BentoLayout.FullRowCard = FullRowCard;

const RowOfCards = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col xs:flex-row xs:flex-wrap">{children}</div>
);
BentoLayout.RowOfCards = RowOfCards;

const HalfRowCard = ({
  className = '',
  style,
  children,
  theme
}: {
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
  theme?: CardThemeType;
}) => (
  <Card
    style={style}
    theme={theme}
    className={`${className} m-3 h-fit min-h-52 flex-1 xs:w-[calc(50%-theme(spacing.6))] xs:min-w-[calc(theme(spacing.66))]`}>
    {children}
  </Card>
);
BentoLayout.HalfRowCard = HalfRowCard;

export default BentoLayout;
