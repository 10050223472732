import { Translation } from '@/components/Utilities/Translation';
import React from 'react';

interface ShowDetailsWidgetProps {
  isMaxRank: boolean;
  onClickOnShowDetailLink: (targetUserActionId?: string) => void;
  handleQuitExercise: () => void;
}

const ShowDetailsWidget: React.FC<ShowDetailsWidgetProps> = ({
  isMaxRank,
  onClickOnShowDetailLink,
  handleQuitExercise
}) => {
  return (
    <>
      <h2 className="mt-auto title [&_strong]:text-accent">
        <Translation
          keyName={
            isMaxRank ? 'globalFeedback.showDetails.endTitle' : 'globalFeedback.showDetails.title'
          }
        />
      </h2>
      <p className="mt-4 text-sm">
        <Translation
          keyName={
            isMaxRank
              ? 'globalFeedback.showDetails.endDescription'
              : 'globalFeedback.showDetails.description'
          }
        />
      </p>

      <p className="-mx-3 -mb-3 mt-3 flex flex-wrap">
        <a
          href="#detail"
          data-testid="feedback-show-detail-button"
          onClick={(event: React.MouseEvent) => {
            if (onClickOnShowDetailLink) {
              event.preventDefault();
              onClickOnShowDetailLink();
            }
          }}
          className={`cta m-3 ${isMaxRank ? 'cta--white' : 'cta--accent'}`}>
          <Translation keyName="globalFeedback.showDetails.button" />
        </a>
        <button
          onClick={() => handleQuitExercise()}
          className={`cta m-3 ${isMaxRank ? 'cta--accent' : 'cta--white'}`}>
          <Translation keyName="globalFeedback.showDetails.quitButton" />
        </button>
      </p>
    </>
  );
};

export default ShowDetailsWidget;
