import { uniqBy } from '@/AppClasses/Utils/uniqBy';
import { HistoryEventTypes } from '../../ExerciseSessionHistory';
import { IHistoryEvent } from '../../Interfaces/ISessionHistory';
import AbstractSolver from '../AbstractSolver';
import { USER_ACTION_TAGS, FEEDBACK_EVALUATIONS } from '../constants';
// Rank configuration
const MINIMUM_RANK = 0;
const MAXIMUM_RANK = 5;
const UNDISCOVERED_ACT_MALUS = 1;
const BAD_ACTION_MALUS = 0.5;
const LIMIT_CASE_MALUS = 1;
const REPEATED_BAD_ACTION_THRESHOLD = 3;
const REPEATED_BAD_ACTION_MALUS = 1.5;

interface ActNode {
  ActName: string;
}

/**
 * Solver class that calculates a rank based on user performance in the exercise
 * Takes into account undiscovered acts, bad actions, and limit cases
 */
class RankSolver extends AbstractSolver {
  /**
   * Calculates the final rank based on various performance metrics
   * @returns {number} Final rank between MINIMUM_RANK and MAXIMUM_RANK
   */
  SolveRank(): number {
    try {
      const actCompletionEvents = this.getActCompletionEvents();
      const userActionFeedbacks = this.getUserActionFeedbackByBadEvaluationAct(actCompletionEvents);

      let rank = MAXIMUM_RANK;

      // Calculate maluses for undiscovered acts
      rank -= this.calculateUndiscoveredActsMalus(actCompletionEvents);

      // Calculate maluses for bad actions
      rank -= this.calculateBadActionsMalus(userActionFeedbacks);
      // bad actions cannot put the rank below 1
      if (rank < 1) {
        rank = 1;
      }

      // Calculate maluses for limit cases
      const limitCaseMalus = this.calculateLimitCaseMalus(userActionFeedbacks);
      rank -= limitCaseMalus;
      // If there was a limit case, cap the final rank at 3
      if (limitCaseMalus > 0) {
        rank = Math.min(rank, 3);
      }

      // Ensure rank stays within bounds
      return this.normalizeRank(rank);
    } catch (error) {
      this.HandleError(error as string);
      return MINIMUM_RANK;
    }
  }

  /**
   * Calculates malus for acts that weren't discovered during the exercise
   * @private
   * @param {IHistoryEvent[]} iActCompletionEvents - Array of act completion events from history
   * @returns {number} Total malus for undiscovered acts, calculated as number of undiscovered acts times UNDISCOVERED_ACT_MALUS
   */
  private calculateUndiscoveredActsMalus(iActCompletionEvents: IHistoryEvent[]): number {
    const actNodes = this.Graph.GetNodesByType('Act');
    const undiscoveredActsCount = this.GetUndiscoveredActsCount(iActCompletionEvents, actNodes);

    return undiscoveredActsCount * UNDISCOVERED_ACT_MALUS;
  }

  /**
   * Calculates malus for bad actions performed by the user
   * Applies increased malus for repeatedly performing the same bad action
   * @private
   * @param {any[]} iUserActionFeedbacks - Array of user action feedback data
   * @returns {number} Total malus for bad actions
   */
  private calculateBadActionsMalus(iUserActionFeedbacks: any[]): number {
    const badActionCounts = this.getBadActionCounts(iUserActionFeedbacks);
    let totalMalus = 0;

    badActionCounts.forEach((count) => {
      if (count > REPEATED_BAD_ACTION_THRESHOLD) {
        totalMalus += REPEATED_BAD_ACTION_MALUS;
      } else {
        totalMalus += count * BAD_ACTION_MALUS;
      }
    });

    return totalMalus;
  }

  /**
   * Calculates malus for limit case situations
   * @private
   * @param {any[]} iUserActionFeedbacks - Array of user action feedback data
   * @returns {number} LIMIT_CASE_MALUS if limit case exists, 0 otherwise
   */
  private calculateLimitCaseMalus(iUserActionFeedbacks: any[]): number {
    const hasLimitCase = iUserActionFeedbacks.some(
      (uaf: any) => uaf.Tags?.some((tag: string) => tag === USER_ACTION_TAGS.LIMIT_CASE)
    );

    return hasLimitCase ? LIMIT_CASE_MALUS : 0;
  }

  /**
   * Retrieves unique act completion events from history
   * @private
   * @returns {IHistoryEvent[]} Array of unique act completion events
   */
  private getActCompletionEvents(): IHistoryEvent[] {
    const actCompletionEvents = this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.ACT_COMPLETION
    });

    return uniqBy(actCompletionEvents, (event: IHistoryEvent) => event.Content.ActName);
  }

  /**
   * Gets all user action feedbacks from acts that were evaluated as BAD
   * @private
   * @param {IHistoryEvent[]} iActCompletionEvents - Array of act completion events
   * @returns {any[]} Array of user action feedback data from badly evaluated acts
   */
  private getUserActionFeedbackByBadEvaluationAct(iActCompletionEvents: IHistoryEvent[]): any[] {
    const userActionsFeedbacksEvents = this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.USER_ACTION_FEEDBACK
    });

    const badEvaluationActNames = iActCompletionEvents
      .filter(
        (actEvent) =>
          actEvent.Content.Evaluation === FEEDBACK_EVALUATIONS.BAD ||
          actEvent.Content.Evaluation === FEEDBACK_EVALUATIONS.FAIL
      )
      .map((actEvent) => actEvent.Content.ActName);

    return userActionsFeedbacksEvents
      .filter((event) => badEvaluationActNames.includes(event.Content.ActName))
      .map((event) =>
        this.Graph.GetFullUserActionFeedbackData(
          event.Content.UserActionFeedbackID,
          event.Content.NodeID
        )
      );
  }

  /**
   * Counts occurrences of bad actions by their user acfeedback ID
   * @private
   * @param {any[]} iUserActionFeedbacks - Array of user action feedback data
   * @returns {Map<string, number>} Map of feedback IDs to their occurrence count
   */
  private getBadActionCounts(iUserActionFeedbacks: any[]): Map<string, number> {
    const badActionUAFs = iUserActionFeedbacks.filter(
      (uaf: any) =>
        uaf.Tags?.some(
          (tag: string) =>
            tag === USER_ACTION_TAGS.BAD_ACTION || tag === USER_ACTION_TAGS.MISSED_OPPORTUNITY
        )
    );

    const uafCounts = new Map<string, number>();
    badActionUAFs.forEach((uaf: any) => {
      const id = uaf.ID;
      if (id) {
        uafCounts.set(id, (uafCounts.get(id) || 0) + 1);
      }
    });

    return uafCounts;
  }

  /**
   * Ensures the rank stays within the defined bounds
   * @private
   * @param {number} rank - The rank to normalize
   * @returns {number} Normalized rank between MINIMUM_RANK and MAXIMUM_RANK
   */
  private normalizeRank(rank: number): number {
    return Math.max(MINIMUM_RANK, Math.min(MAXIMUM_RANK, rank));
  }

  /**
   * Counts the number of acts that weren't discovered during the exercise
   * @private
   * @param {HistoryEvent[]} actCompletionEvents - Array of act completion events
   * @param {ActNode[]} actNodes - Array of all available act nodes
   * @returns {number} Count of undiscovered acts
   */
  private GetUndiscoveredActsCount(
    actCompletionEvents: IHistoryEvent[],
    actNodes: ActNode[]
  ): number {
    return actNodes.filter(
      (actNode) => !actCompletionEvents.some((event) => event.Content.ActName === actNode.ActName)
    ).length;
  }
}

export default RankSolver;
