import Card from '@UIKit/Card/Card';
import { StepContext } from '@UIKit/Stepper/Stepper';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DetailedFeedbackModel, UserActionModel } from '@/models/DetailedFeedbackModel';
import { UserActionFeedbacks } from '@/components/Feedback/FeedbackDetail/UserActionFeedbacks';
import { UserActions } from '@/components/Feedback/FeedbackDetail/UserActions';
import Modal from '@UIKit/Modal/Modal';
import { useParams } from 'react-router-dom';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import SmCover from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates-sm.jpg';
import SmCover2x from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates-sm@2x.jpg';
import Cover from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates.jpg';
import Cover2x from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates@2x.jpg';
import { useExerciseNavigation } from '@/hooks/useExerciseNavigation';
import { useNavigate } from 'react-router-dom';
import { Translation } from '@/components/Utilities/Translation';

export const FeedbackTabsContext = React.createContext({
  activeUserActionId: '',
  setActiveUserActionId: () => {},
  activeUserActionFeedbackId: '',
  setActiveUserActionFeedbackId: () => {}
} as {
  activeUserActionId: string;
  setActiveUserActionId: Dispatch<SetStateAction<string>>;
  activeUserActionFeedbackId: string;
  setActiveUserActionFeedbackId: Dispatch<SetStateAction<string>>;
});

const FeedbackDetail = ({
  detailedFeedbacks,
  handleQuitExercise,
  className = '',
  onModalChange,
  isMaxRank
}: {
  detailedFeedbacks: DetailedFeedbackModel[];
  handleQuitExercise: () => void;
  className?: string;
  onModalChange: (isOpen: boolean) => void;
  isMaxRank: boolean;
}) => {
  if (!detailedFeedbacks) {
    return null;
  }

  const navigate = useNavigate();
  const { exerciseID, exerciseSessionID } = useParams();
  const { restartExercise } = useExerciseNavigation(exerciseID);
  const activeActId = React.useContext(StepContext).activeStepId;
  const [isUADetailsTextModalOpened, setIsUADetailsTextModalOpened]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [detailsTextModalContent, setDetailsTextModalContent]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');
  const [detailsTextModalTitle, setDetailsTextModalTitle]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');

  const toggleModal = (title: string, content: string) => {
    setDetailsTextModalContent(content);
    setDetailsTextModalTitle(title);
    const newState = !isUADetailsTextModalOpened;
    setIsUADetailsTextModalOpened(newState);
    onModalChange(newState);
  };

  const [activeUserActionId, setActiveUserActionId]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>('');
  const [activeUserActionFeedbackId, setActiveUserActionFeedbackId]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');

  const getActiveAct = () =>
    detailedFeedbacks.find((act: DetailedFeedbackModel) => act.id === activeActId) ||
    detailedFeedbacks[0];

  const handleNodeRerunClick = (rerunNodeID: string) => {
    navigate(`/exercise/${exerciseID}/${exerciseSessionID}/rerun/${rerunNodeID}/briefing`);
  };

  useEffect(() => {
    const activeAct = getActiveAct();

    if (activeAct?.userActions.length === 0) {
      return;
    }

    setActiveUserActionId(activeAct?.userActions[0].id);
  }, [activeActId]);

  useEffect(() => {
    const activeAct = getActiveAct();

    const activeUserAction: UserActionModel | undefined = activeAct?.userActions.find(
      (userAction: UserActionModel) => userAction.id === activeUserActionId
    );

    setActiveUserActionFeedbackId(() => {
      if (activeUserAction?.recommendations && activeUserAction.recommendations.length > 1) {
        return activeUserAction?.recommendations[0].id;
      }

      return '';
    });
  }, [activeActId, activeUserActionId]);

  return (
    <FeedbackTabsContext.Provider
      value={{
        activeUserActionId,
        setActiveUserActionId,
        activeUserActionFeedbackId,
        setActiveUserActionFeedbackId
      }}>
      {detailedFeedbacks.map((act: DetailedFeedbackModel) => (
        <section
          key={act.id}
          className={`${className} ${
            activeActId !== act.id ? 'hidden' : 'animate-fadeIn'
          } w-full overflow-hidden`}
          role="tabpanel"
          id={'panel-' + act.id}
          aria-labelledby={`tab-${act.id}`}
          tabIndex={0}>
          <h2 className="mb-6 text-center title-lg">
            {act.displayedName} : Vos interventions clés
          </h2>
          {act.userActions.length ? (
            <div className="lg:flex">
              <Card
                className="max-lg:-mb-6 max-lg:rounded-b-none max-lg:pb-11 lg:-mr-6 lg:flex lg:max-w-lg lg:flex-1 lg:flex-col lg:rounded-r-none lg:pb-12 lg:pl-12 lg:pr-17"
                theme="BRAND">
                <UserActions
                  onModalClick={toggleModal}
                  key={act.id}
                  className="mb-auto"
                  userActions={act?.userActions}
                />
              </Card>

              {act.userActions.map((userAction: UserActionModel) => {
                return (
                  <React.Fragment key={userAction.id}>
                    <Card
                      id={'extra-panel-' + userAction.id}
                      role="tabpanel"
                      aria-labelledby={`tab-${userAction.id}`}
                      tabIndex={0}
                      className={`${
                        activeUserActionId !== userAction.id ? 'hidden' : ''
                      } lg:flex-1 lg:p-12`}>
                      <div
                        className={`${
                          activeUserActionId === userAction.id ? 'animate-fadeIn' : ''
                        } lg:flex lg:h-full lg:flex-col`}>
                        <h3 className="label [&.label]:text-sm">
                          {userAction.evaluation === 'GOOD'
                            ? 'Votre bonne intervention en détail'
                            : 'Que faire à la place ?'}
                        </h3>

                        <UserActionFeedbacks
                          className={`mb-auto ${
                            userAction.recommendations.length > 1 ? 'mt-4' : 'mt-3'
                          } ${act?.isAdvice ? '[&_.text-accent]:text-advice' : ''}`}
                          feedbacks={userAction.recommendations}
                        />

                        {userAction.evaluation !== 'GOOD' && (
                          <button
                            className="cta cta--accent mt-8 w-fit"
                            data-testid="feedback-rerun-button"
                            onClick={() => handleNodeRerunClick(userAction.rerunNodeID)}>
                            Ré-essayer ce moment
                          </button>
                        )}
                      </div>
                    </Card>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className="flex h-full items-center justify-center text-red-500">
              Désolé, une erreur est survenue lors du chargement des données.
            </div>
          )}

          <p className="-mx-6 -mb-3 mt-9 text-center">
            <button className="cta cta--white mx-6 my-3" onClick={() => restartExercise()}>
              Recommencer au début
            </button>
            <button
              className={`cta mx-6 my-3 ${isMaxRank ? 'cta--accent' : 'cta--white'}`}
              onClick={() => handleQuitExercise()}>
              <Translation keyName="globalFeedback.showDetails.quitButton" />
            </button>
          </p>
        </section>
      ))}

      {isUADetailsTextModalOpened && (
        <Modal onClose={() => toggleModal('', '')}>
          <Modal.Header
            cover={{
              alt: '',
              smImage: {
                src: SmCover,
                height: 250,
                width: 640
              },
              smImage2x: {
                src: SmCover2x,
                height: 500,
                width: 1280
              },
              image: {
                src: Cover,
                height: 250,
                width: 1000
              },
              image2x: {
                src: Cover2x,
                height: 500,
                width: 2000
              }
            }}>
            <Modal.Title label={<>Tout savoir sur&hellip;</>}>{detailsTextModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Content>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(detailsTextModalContent) }}></div>
          </Modal.Content>
        </Modal>
      )}
    </FeedbackTabsContext.Provider>
  );
};
export default FeedbackDetail;
