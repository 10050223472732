import { useEffect, useState } from 'react';
import log from 'loglevel';
import { DetailedFeedbackModel, ImprovementAxesModel } from '@/models/DetailedFeedbackModel';

type ExerciseSessionEvent = {
  ExerciseSessionID: string;
  EventID: string;
  UserID: string;
  EventType: string;
  Date: Date;
  Content: any;
};

const DETAILED_FEEDBACKS_EVENT_TYPE = 'DetailedFeedbacks';
const GENERATIVE_FEEDBACK_EVENT_TYPE = 'GenerativeFeedback';
const FEEDBACK_RANK_EVENT_TYPE = 'FeedbackRank';

export function useFeedback(exerciseSessionID: string | undefined) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [detailedFeedbacks, setDetailedFeedback] = useState<DetailedFeedbackModel[]>();
  const [improvementAxes, setImprovementAxes] = useState<ImprovementAxesModel[]>();
  const [feedbackRank, setFeedbackRank] = useState<number>();
  const [generativeFeedback, setGenerativeFeedback] = useState<{
    analysis: string;
    enhancement: string;
  }>();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      if (!exerciseSessionID) {
        setIsLoading(false);
        return;
      }

      try {
        const sessionHistory: ExerciseSessionEvent[] = await window.sdk
          .exerciseSession()
          .getExerciseSessionHistory(exerciseSessionID);

        const lastDetailedFeedbacksEvent = getLastEventByType(
          sessionHistory,
          DETAILED_FEEDBACKS_EVENT_TYPE
        );

        const lastGenerativeFeedbackEvent = getLastEventByType(
          sessionHistory,
          GENERATIVE_FEEDBACK_EVENT_TYPE
        );

        const lastFeedbackRankEvent = getLastEventByType(sessionHistory, FEEDBACK_RANK_EVENT_TYPE);

        const improvementAxes =
          lastDetailedFeedbacksEvent?.Content.DetailedFeedbacks.improvementAxes;
        const detailedFeedbacks =
          lastDetailedFeedbacksEvent?.Content.DetailedFeedbacks.solvedActsFeedbacks;
        const generativeFeedback = lastGenerativeFeedbackEvent?.Content.GenerativeFeedback;
        const feedbackRank = lastFeedbackRankEvent?.Content.Rank;

        setDetailedFeedback(detailedFeedbacks);
        setImprovementAxes(improvementAxes);
        setGenerativeFeedback(generativeFeedback);
        setFeedbackRank(feedbackRank);
        setIsLoading(false);
      } catch (error: any) {
        log.error(error);
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchFeedbacks();
  }, [exerciseSessionID]);

  return {
    detailedFeedbacks,
    improvementAxes,
    generativeFeedback,
    feedbackRank,
    isLoading,
    errorMessage
  };
}

function getLastEventByType(
  events: ExerciseSessionEvent[],
  eventType: string
): ExerciseSessionEvent | undefined {
  for (let i = events.length - 1; i >= 0; i--) {
    if (events[i].EventType === eventType) {
      return events[i];
    }
  }
  return undefined;
}
