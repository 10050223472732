import { Translation } from '@/components/Utilities/Translation';
import React from 'react';

interface GenerativeFeedbackWidgetProps {
  analysisFeedback: string;
  enhancementFeedback: string;
  onShowMoreClick?: () => void;
}

const GenerativeFeedbackWidget: React.FC<GenerativeFeedbackWidgetProps> = ({
  analysisFeedback,
  enhancementFeedback,
  onShowMoreClick
}) => {
  return (
    <>
      <div className="flex h-full flex-col justify-between">
        {!analysisFeedback && !enhancementFeedback ? (
          <p className="flex h-full items-center justify-center text-center text-base text-red-500">
            <Translation keyName="globalFeedback.generative.card.error" />
          </p>
        ) : (
          <>
            <p className="text-[17px]">
              <span className="font-semibold">
                <Translation keyName="globalFeedback.generative.card.analysis" />
              </span>
              {analysisFeedback}
            </p>
            <p className="mt-2 text-[17px]">
              <span className="font-semibold">
                <Translation keyName="globalFeedback.generative.card.enhancement" />
              </span>
              {enhancementFeedback}
            </p>
          </>
        )}
        <button className="cta ml-auto" onClick={onShowMoreClick}>
          <Translation keyName="globalFeedback.generative.card.showMoreButton" />
        </button>
      </div>
    </>
  );
};

export default GenerativeFeedbackWidget;
