import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { ExerciseContext } from '@/contexts/ExerciseContext';
import log from 'loglevel';
import { isSafari } from 'react-device-detect';
import TopBar from '@/components/TopBar/TopBar';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRetryRunInfo } from '@/hooks/useRetryRunInfo';
import { RecommendationCard } from '@/components/Briefing/RecommendationCard';
import { CollaboratorCard } from '@/components/Briefing/CollaboratorCard';
import { ObjectiveCard } from '@/components/Briefing/ObjectiveCard';
import { ContextCard } from '@/components/Briefing/ContextCard';
import { BriefingSidebar } from '@/components/Briefing/BriefingSidebar';
import { RecommendationDetailModal } from '@/components/Briefing/RecommendationDetailModal';

const ExerciseBriefingPage = () => {
  const {
    ExerciseID,
    setExerciseID,
    jsonGraph,
    ExerciseGraph,
    cache,
    setCache,
    setRerunNodeID,
    setExerciseSessionID,
    ExerciseSessionHistory,
    setStep,
    transcriptionSession,
    setTranscriptionSession,
    resetExerciseContext
  }: any = useContext(ExerciseContext);
  const navigate = useNavigate();
  const { id, RerunNodeID, ExerciseSessionID } = useParams();
  const [context, setContext] = useState('');
  const [objectives, setObjectives] = useState('');
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [isUADetailsTextModalOpened, setIsUADetailsTextModalOpened]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);
  const [detailsTextModalContent, setDetailsTextModalContent]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');
  const [detailsTextModalTitle, setDetailsTextModalTitle]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>('');

  useEffect(() => {
    if (isSafari && !window.sdk.isInIframe()) {
      log.debug('safari in non iframe we do not go to fullscreen');
    } else {
      window.sdk.openFullscreen();
    }

    if (id && id !== ExerciseID) {
      setExerciseID(id);
      window.sdk.setRedirectUrl(`/exercise/${id}`);
    }

    if (RerunNodeID) {
      setRerunNodeID(RerunNodeID);
    }

    if (ExerciseSessionID) {
      setExerciseSessionID(ExerciseSessionID);
    }
  }, []);

  useEffect(() => {
    const preloadVideo = async () => {
      await window.sdk
        .exercise()
        .preloadExerciseAssets(ExerciseGraph, (progressionInPercent: string) => {
          setCache({
            totalProgression: parseInt(progressionInPercent, 10)
          });
        });
    };

    if (ExerciseGraph) {
      preloadVideo();
    }
  }, [jsonGraph, ExerciseGraph]);

  useEffect(() => {
    // Exit early if exercise graph is not available
    if (!ExerciseGraph) {
      return;
    }

    // Handle first run of the exercise - no rerun or session info
    const isFirstRun = (!RerunNodeID && !ExerciseSessionID) || !ExerciseSessionHistory;
    if (isFirstRun) {
      setObjectives(jsonGraph.IntroPanel.Objectives);
      setContext(jsonGraph.IntroPanel.Context);
      return;
    }

    // Get retry run information if available
    const retryRunInfo = useRetryRunInfo({
      exerciseSessionHistory: ExerciseSessionHistory,
      exerciseGraph: ExerciseGraph,
      rerunNodeID: RerunNodeID ?? ''
    });

    // Update state with retry run information
    if (retryRunInfo) {
      const { rerunSceneNode, recommendedUserActionsFeedbacks } = retryRunInfo;
      setObjectives(rerunSceneNode.Objectives || jsonGraph.IntroPanel.Objectives);
      setContext(rerunSceneNode.Context || jsonGraph.IntroPanel.Context);
      setRecommendations(recommendedUserActionsFeedbacks);
    }
  }, [ExerciseGraph, jsonGraph, RerunNodeID, ExerciseSessionID, ExerciseSessionHistory]);

  useEffect(() => {
    if (cache.totalProgression === 100) {
      micValidation();
    }
  }, [cache.totalProgression]);

  const toggleModal = (title: string, content: string) => {
    setDetailsTextModalContent(content);
    setDetailsTextModalTitle(title);
    const newState = !isUADetailsTextModalOpened;
    setIsUADetailsTextModalOpened(newState);
  };

  const micValidation = async () => {
    if (
      (jsonGraph.CustomizationValues && jsonGraph.CustomizationValues.skipCamMicSetup) ||
      (window.testMode && window.testMode.noDevicesMode)
    ) {
      goToVisio();
      return;
    }

    if (window.testMode.skipSTTSetupTest || window.testMode.noDevicesMode) {
      log.debug('micvalidation skipping');
      OnSpeechDetected("c'est parti", null);
    }

    await updateDevices();

    if (window.testMode.skipDeviceActivation) {
      return;
    }

    try {
      const videoconfManager = window.sdk.videoconf();
      await videoconfManager.mediaDevices().getUserMedia();

      const speechToTextManager = videoconfManager.getSpeechToTextManager();
      const localTranscriptionSession = await speechToTextManager.createMicValidationSession(
        (iResult: string) => {
          OnSpeechDetected(iResult, localTranscriptionSession);
        }
      );
      setTranscriptionSession(localTranscriptionSession);
      await localTranscriptionSession.start();
    } catch (error) {
      log.error('Failed to initialize media devices or speech recognition:', error);
      // Handle the error appropriately, maybe show a message to the user
      return;
    }
  };

  const updateDevices = async () => {
    let deviceAudioId = window.localStorage.getItem('device-audioinput');
    if (!deviceAudioId) {
      await window.sdk
        .videoconf()
        .mediaDevices()
        .update(
          'audioinput',
          window.sdk.videoconf().mediaDevices().getCurrentDeviceId('audioinput')
        );
    }

    let deviceVideoId = window.localStorage.getItem('device-videoinput');
    if (!deviceVideoId) {
      await window.sdk
        .videoconf()
        .mediaDevices()
        .update(
          'videoinput',
          window.sdk.videoconf().mediaDevices().getCurrentDeviceId('videoinput')
        );
    }
  };

  const OnSpeechDetected = (iResult: string, transcriptionSession: any) => {
    if (!iResult) return;

    const regex = /c'est parti/g;
    const englishRegexp = /let's go/g;
    const tokenFound = iResult.match(regex) || iResult.match(englishRegexp);

    if (tokenFound) {
      if (window.zE) {
        window.zE('webWidget', 'hide');
        window.zE('webWidget:on', 'close', function () {
          window.zE('webWidget', 'hide');
        });
      }

      if (transcriptionSession) {
        transcriptionSession.close();
      }

      goToVisio();
    }
  };

  const goToVisio = () => {
    if (transcriptionSession) {
      transcriptionSession.close();
    }
    navigate(`/exercise/${id}/visio`, { replace: false });
  };

  const goToSettings = () => {
    if (transcriptionSession) {
      transcriptionSession.close();
    }

    resetExerciseContext();
    setStep('settings');

    navigate(`/exercise/${id}/`, { replace: false });
  };

  const showZendesk = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'show');
    } else {
      window.location = 'mailto:support@practicio.fr' as any as Location;
    }
  };

  return (
    <>
      <TopBar className="sticky left-0 top-0 z-50 w-full" />

      <BriefingSidebar
        cache={cache}
        onSpeechDetected={OnSpeechDetected}
        goToSettings={goToSettings}
        showZendesk={showZendesk}
      />

      {context && objectives && (
        <section className="px-4 py-6 bg-blurred-sophie max-lg:pb-8 sm:px-6 lg:relative lg:ml-auto lg:flex lg:min-h-[calc(100vh-theme(spacing.12))] lg:w-3/4 lg:items-center">
          <div className="lg:mx-auto lg:w-full lg:max-w-[70%]">
            <ContextCard context={context} />

            {!recommendations.length && (
              <CollaboratorCard
                pictureURL={jsonGraph.IntroPanel.Collaborator.PictureURL}
                description={jsonGraph.IntroPanel.Collaborator.Description}
                difficulty={jsonGraph.IntroPanel.Collaborator.Difficulty}
              />
            )}

            <ObjectiveCard objectives={objectives} label={jsonGraph.IntroPanel?.ObjectivesLabel} />

            {recommendations.length > 0 && (
              <>
                <RecommendationCard recommendations={recommendations} toggleModal={toggleModal} />
                <RecommendationDetailModal
                  isOpen={isUADetailsTextModalOpened}
                  onClose={() => toggleModal('', '')}
                  title={detailsTextModalTitle}
                  content={detailsTextModalContent}
                />
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default ExerciseBriefingPage;
