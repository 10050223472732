import React from 'react';
import Star_empty from './images/star_empty.svg';
import Star_full from './images/star_full.svg';
import Star_half from './images/star_half.svg';

const MAX_RANK = 5;

interface RankWidgetProps {
  rank: number;
  onShowMoreClick: () => void;
}

const RankWidget: React.FC<RankWidgetProps> = ({ rank, onShowMoreClick }) => {
  const renderStars = (rank: number) => {
    const stars = [];

    for (let i = 0; i < MAX_RANK; i++) {
      const isFull = i < Math.floor(rank);
      const isHalf = i === Math.floor(rank) && rank % 1 !== 0;

      const starSrc = isFull ? Star_full : isHalf ? Star_half : Star_empty;

      stars.push(<img key={i} src={starSrc} alt="rank star" className="mt-12 w-1/6" />);
    }

    return stars;
  };

  return (
    <>
      <div className="flex justify-center gap-2">{renderStars(rank)}</div>
      <p className="mt-8">
        <button className="cta" onClick={onShowMoreClick}>
          En savoir plus
        </button>
      </p>
    </>
  );
};

export default RankWidget;
