import React from 'react';
import { ImprovementAxesModel } from '@/models/DetailedFeedbackModel';
import { Translation } from '@/components/Utilities/Translation';

interface ImprovementAxesWidgetProps {
  improvementAxes?: ImprovementAxesModel[];
  onClickOnShowDetailLink: (targetUserActionId?: string) => void;
}
const ImprovementAxesWidget: React.FC<ImprovementAxesWidgetProps> = ({
  improvementAxes,
  onClickOnShowDetailLink
}) => {
  return (
    <>
      <h1 className="mb-8 mt-4 title">
        <Translation keyName="globalFeedback.improvementAxes.title" />
      </h1>

      {!improvementAxes?.length && (
        <p className="mb-8 mt-4 text-lg font-light">
          <Translation keyName="globalFeedback.improvementAxes.congrats" />
        </p>
      )}

      <ul className="list-none text-xl font-light [&_li+li]:mt-2">
        {improvementAxes?.map((item: ImprovementAxesModel) => (
          <li
            key={item.index}
            data-testid={`improvement-axes-${item.index}`}
            onClick={() => onClickOnShowDetailLink(item.index)}
            className={`cursor-pointer ${
              item.kind === 'WARNING' ? 'marker-warning' : 'marker-tips'
            } pb-2`}>
            {item.label}
          </li>
        ))}
      </ul>
    </>
  );
};

export default ImprovementAxesWidget;
