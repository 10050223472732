import Modal from '@UIKit/Modal/Modal';
import React, { useState } from 'react';
import { Translation } from '@/components/Utilities/Translation';
import BentoLayout from './BentoLayout';
import { ImprovementAxesModel } from '@/models/DetailedFeedbackModel';
import ImprovementAxesWidget from './ImprovementAxesWidget';
import GenerativeFeedbackWidget from './GenerativeFeedbackWidget';
import ShowDetailsWidget from './ShowDetailsWidget';
import RankWidget from './RankWidget';

const FeedbackGlobal = ({
  className = '',
  improvementAxes,
  generativeFeedback,
  feedbackRank,
  isMaxRank,
  onClickOnShowDetailLink = () => {},
  handleQuitExercise,
  onModalChange
}: {
  className?: string;
  improvementAxes?: ImprovementAxesModel[];
  generativeFeedback?: {
    analysis: string;
    enhancement: string;
  };
  feedbackRank: number;
  isMaxRank: boolean;
  onClickOnShowDetailLink?: (targetUserActionId?: string) => void;
  handleQuitExercise: () => void;
  onModalChange: (isOpen: boolean) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  const toggleModal = (type: 'rank' | 'feedback' | null) => {
    if (type === null) {
      setIsModalOpen(false);
      onModalChange(false);
      return;
    }

    const modalContentKeys = {
      rank: {
        title: 'globalFeedback.rank.modal.title',
        content: 'globalFeedback.rank.modal.explanations'
      },
      feedback: {
        title: 'globalFeedback.generative.modal.title',
        content: 'globalFeedback.generative.modal.explanations'
      }
    };

    const config = modalContentKeys[type];

    setIsModalOpen(true);
    setModalTitle(config.title);
    setModalContent(config.content);
    onModalChange(true);
  };

  const toggleRankExplanationsModal = () => toggleModal('rank');
  const toggleGenerativeFeedbackModal = () => toggleModal('feedback');

  return (
    <>
      <BentoLayout className={`${className} flex-1 sm:px-3`}>
        <BentoLayout.OneThirdColumn>
          <BentoLayout.FullRowCard
            className="relative flex flex-1 animate-fadeIn flex-col justify-center overflow-hidden text-center before:absolute before:left-1/2 before:top-1/2 before:h-[1594px] before:w-[1776px] before:-translate-x-1/2 before:-translate-y-1/2 before:bg-accent before:mask-brand-watermark before:[mask-position:calc(50%_+_540px)_calc(50%_-_450px)] before:[mask-repeat:no-repeat] [&_>_*]:relative"
            theme="BRAND">
            <RankWidget onShowMoreClick={toggleRankExplanationsModal} rank={feedbackRank} />
          </BentoLayout.FullRowCard>
          <BentoLayout.FullRowCard
            theme="BRAND"
            className="relative flex flex-1 animate-fadeIn flex-col overflow-hidden [&_>_*]:relative">
            <ImprovementAxesWidget
              improvementAxes={improvementAxes}
              onClickOnShowDetailLink={onClickOnShowDetailLink}
            />
          </BentoLayout.FullRowCard>
        </BentoLayout.OneThirdColumn>
        <BentoLayout.TwoThirdsColumn>
          <BentoLayout.FullRowCard className="not-important-opacity-0 relative flex flex-1 animate-fadeIn flex-col overflow-hidden [animation-delay:.5s] before:absolute before:inset-0 before:bg-brand-watermark before:bg-[length:400px_auto] before:bg-[position:calc(100%_+_50px)_-40px] before:bg-no-repeat [&_>_*]:relative">
            {generativeFeedback ? (
              <GenerativeFeedbackWidget
                analysisFeedback={generativeFeedback.analysis}
                enhancementFeedback={generativeFeedback.enhancement}
                onShowMoreClick={toggleGenerativeFeedbackModal}
              />
            ) : (
              <div className="flex h-full w-[850px] items-center justify-center text-red-500">
                Désolé, une erreur est survenue lors du chargement des données.
              </div>
            )}
          </BentoLayout.FullRowCard>
          <BentoLayout.RowOfCards>
            <BentoLayout.HalfRowCard className="not-important-opacity-0 flex animate-fadeIn flex-col justify-between [animation-delay:.3s] [&_>_*]:relative">
              <ShowDetailsWidget
                isMaxRank={isMaxRank}
                onClickOnShowDetailLink={onClickOnShowDetailLink}
                handleQuitExercise={handleQuitExercise}
              />
            </BentoLayout.HalfRowCard>
          </BentoLayout.RowOfCards>
        </BentoLayout.TwoThirdsColumn>
      </BentoLayout>

      {isModalOpen && (
        <Modal onClose={() => toggleModal(null)}>
          <Modal.Header>
            <Modal.Title>
              <Translation keyName={modalTitle} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Content>
            <Translation keyName={modalContent} />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default FeedbackGlobal;
