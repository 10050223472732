import log from 'loglevel';
import AbstractSolver from '../AbstractSolver';
import { HistoryEventTypes } from '../../ExerciseSessionHistory';

type GenerativeFeedback = {
  analysis: string;
  enhancement: string;
};

class GenerativeFeedbackSolver extends AbstractSolver {
  /**
   * Solves and generates feedback based on the dialog history between user and bot
   * @returns {Promise<Record<string, any>>} Object containing the generated feedback
   */
  async SolveGenerativeFeedback(): Promise<Record<string, any>> {
    if (window.testMode.forceUserActionsMode) {
      return {
        analysis: '',
        enhancement: ''
      };
    }

    const userSpeechEvents = this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.USER_SPEECH
    });

    const botSpeechEvents = this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.BOT_SPEECH
    });

    const dialogEvents = [...userSpeechEvents, ...botSpeechEvents];
    dialogEvents.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

    const dialog = this.FormatDialog(dialogEvents);

    const feedback = await this.FetchGenerativeFeedback(dialog);
    return feedback;
  }

  /**
   * Formats dialog events into a string representation of the conversation
   * @private
   * @param {any[]} iDialogEvents - Array of dialog events containing user and bot speech
   * @returns {string} Formatted dialog string
   */
  private FormatDialog(iDialogEvents: any[]): string {
    let formattedDialog = '';

    for (const dialogEvent of iDialogEvents) {
      if (dialogEvent.EventType === HistoryEventTypes.USER_SPEECH) {
        formattedDialog += `- Camille: ${dialogEvent.Content.BeautifiedSpeech}\n`;
      }

      if (dialogEvent.EventType === HistoryEventTypes.BOT_SPEECH) {
        formattedDialog += `- Raphaël: ${dialogEvent.Content.Speech}\n`;
      }
    }
    const dialog = formattedDialog.trim();
    log.debug('generative feedback - dialog : ', dialog);
    return dialog;
  }

  /**
   * Fetches generative feedback from the LLM API
   * @private
   * @param {string} iDialog - Formatted dialog string
   * @returns {Promise<Record<string, any>>} Generated feedback response
   */
  private async FetchGenerativeFeedback(iDialog: string): Promise<Record<string, any>> {
    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/generate-feedback', {
        body: {
          dialog: iDialog,
          exerciseID: this.Graph.ExerciseID,
          exerciseSessionID: this.Graph.CurrentExerciseSessionID
        }
      });

      log.debug('generative feedback - answer : ', gptAnswer);

      return gptAnswer.generativeFeedback as GenerativeFeedback;
    } catch (error) {
      this.HandleError(error as string);
      return {
        analysis: '',
        enhancement: ''
      };
    }
  }
}

export default GenerativeFeedbackSolver;
