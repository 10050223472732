import log from 'loglevel';
import Utils from '../Utils/Utils';

export const HistoryEventTypes = {
  RANDOM_SEED: 'RandomSeed',
  NODE_ACTIVATION: 'NodeActivation',
  ACT_COMPLETION: 'ActCompletion',
  BOT_SPEECH: 'BotSpeech',
  BRANCHING_DECISION: 'BranchingDecision',
  BRANCHING_DECISION_RESULTS: 'BranchingDecisionResults',
  BRANCHING_DECISION_SHORTCUT: 'BranchingDecisionShortcut',
  DETAILED_FEEDBACKS: 'DetailedFeedbacks',
  GENERATIVE_FEEDBACK: 'GenerativeFeedback',
  POPPED_USER_ACTIONS: 'PoppedUserActions',
  REWIND: 'Rewind',
  SCENE_ACTIVATION: 'Scene',
  COUNTERS_INCREMENTATION: 'CountersIncrementation',
  STOP: 'Stop',
  TROPHY: 'Trophy',
  USER_ACTION: 'UserAction',
  USER_ACTION_FEEDBACK: 'UserActionFeedback',
  USER_SPEECH: 'UserSpeech',
  NARRATIVE_END: 'NarrativeEnd',
  PEDAGOGICAL_END: 'PedagogicalEnd',
  ACHIEVEMENT: 'Achievement',
  PEDAGOGICAL_ADDITION: 'PedagogicalAddition',
  PEDAGOGICAL_RECOMMENDATION: 'PedagogicalRecommendation',
  DETECTION_ISSUE: 'DetectionIssue',
  SCENE_EVALUATION: 'SceneEvaluation',
  FEEDBACK_RANK: 'FeedbackRank'
};

export const SpeechSourceType = {
  human: 'human',
  synthetic: 'synthetic',
  placeholder: 'placeholder',
  bdShortcut: 'bdShortcut',
  forced: 'forced'
};

export default class ExerciseSessionHistory {
  Graph;
  History = [];
  UserName = '';
  UniqueIDIncrementer = 0;

  constructor(iGraph, iUserName) {
    this.Graph = iGraph;
    this.UserName = iUserName;
    this.UniqueIDIncrementer = 0;
  }

  // TODO: link with the Smart Branching Decision
  // Save achievements to display in real time

  async AddEvent(iEventType, iContent, iWaitForDB = true, iDate = null) {
    const currentDate = iDate ? iDate : new Date();

    const eventID = Utils.CreateObjectIDWithIncrement(
      currentDate,
      window.sdk.user().userID,
      this.UniqueIDIncrementer++
    );

    let newEvent = {
      EventID: eventID,
      ExerciseSessionID: this.Graph.CurrentExerciseSessionID,
      Date: currentDate.toISOString(),
      EventType: iEventType,
      Content: iContent
    };

    this.History.push(newEvent);

    // Log history event in DynamoDB
    const promise = window.sdk.exerciseSession().addEventToHistory(newEvent);
    if (iWaitForDB) {
      await promise;
    }

    return eventID;
  }

  async AddRewind(iTargetNodeID) {
    return await this.AddEvent(HistoryEventTypes.REWIND, {
      TargetNodeID: iTargetNodeID
    });
  }

  async AddUserSpeech(
    iNodeID,
    iAnalysisTaskID,
    iSpeech,
    iDate,
    iBranchingDecisionDatabaseID,
    iSpeechSource = SpeechSourceType.human,
    iBeautifiedSpeech = ''
  ) {
    return await this.AddEvent(
      HistoryEventTypes.USER_SPEECH,
      {
        NodeID: iNodeID,
        BranchingDecisionNodeID: this.Graph.LastBranchingDecisionNode.ID,
        Actor: 'User',
        Character: this.UserName,
        AnalysisTaskID: iAnalysisTaskID,
        Speech: iSpeech,
        BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID,
        SpeechSource: iSpeechSource,
        BeautifiedSpeech: iBeautifiedSpeech
      },
      false,
      iDate
    );
  }

  async AddBotSpeech(iNodeID, iBotName, iBotVideoName, iTranscript) {
    return await this.AddEvent(HistoryEventTypes.BOT_SPEECH, {
      NodeID: iNodeID,
      Actor: 'Bot',
      Character: iBotName,
      Video: iBotVideoName,
      Speech: iTranscript,
      SceneNodeID: this.Graph.GetCurrentSceneNodeID()
    });
  }

  async AddBranchingDecisionResults(
    iNodeID,
    iBranchingDecisionResults,
    iSceneName,
    iActName,
    iBranchingDecisionDatabaseID
  ) {
    return await this.AddEvent(HistoryEventTypes.BRANCHING_DECISION_RESULTS, {
      NodeID: iNodeID,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID,
      BranchingDecisionResults: iBranchingDecisionResults,
      SceneName: iSceneName,
      ActName: iActName
    });
  }

  async AddUserAction(iNodeID, iUserActionID, iPhase, iBranchingDecisionDatabaseID) {
    return await this.AddEvent(HistoryEventTypes.USER_ACTION, {
      NodeID: iNodeID,
      UserActionID: iUserActionID,
      Phase: iPhase,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID
    });
  }

  async AddUserActionFeedback(
    iNodeID,
    iUserActionFeedbackID,
    iPhase,
    iBranchingDecisionDatabaseID,
    iSceneNodeID,
    iUserActionsFeedbacksSpeechParts = []
  ) {
    return await this.AddEvent(HistoryEventTypes.USER_ACTION_FEEDBACK, {
      NodeID: iNodeID,
      UserActionFeedbackID: iUserActionFeedbackID,
      Phase: iPhase,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID,
      SceneNodeID: iSceneNodeID,
      SpeechParts: iUserActionsFeedbacksSpeechParts,
      ActName: this.Graph.GetCurrentActName()
    });
  }

  async AddSpeechPartsToUserActionFeedback(
    iBDDatabaseID,
    iFeedbackID,
    iUserActionsFeedbacksSpeechParts
  ) {
    // Find the UserActionFeedback event from iBDDatabaseID and iFeedbackID
    let userActionFeedbackEvent = this.History.find(
      (event) =>
        event.EventType === HistoryEventTypes.USER_ACTION_FEEDBACK &&
        event.Content.BranchingDecisionDatabaseID === iBDDatabaseID &&
        event.Content.UserActionFeedbackID === iFeedbackID
    );

    // Update the UserActionFeedback event with the speech parts
    userActionFeedbackEvent.Content.SpeechParts = iUserActionsFeedbacksSpeechParts;

    // Update history event in DynamoDB
    await window.sdk.exerciseSession().updateHistoryEvent(userActionFeedbackEvent);
  }

  async AddPoppedUserActions(iNodeID, iUserActionFeedbacks, iPhase, iBranchingDecisionDatabaseID) {
    // Save a UserAction when it has popped.
    return await this.AddEvent(HistoryEventTypes.POPPED_USER_ACTIONS, {
      NodeID: iNodeID,
      PoppedUserActions: iUserActionFeedbacks,
      Phase: iPhase,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID
    });
  }

  async AddNarrativeEnd(iNodeID, iNarrativeEndName) {
    // Save chosen narrative from narrative solver
    return await this.AddEvent(HistoryEventTypes.NARRATIVE_END, {
      NodeID: iNodeID,
      Name: iNarrativeEndName
    });
  }

  async AddAchievementsDone(iAchievementsToDisplay) {
    // Save achievements done
    for (const achievement of iAchievementsToDisplay) {
      await this.AddEvent(HistoryEventTypes.ACHIEVEMENT, {
        ID: achievement.ID,
        Type: achievement.Type,
        DisplayedName: achievement.DisplayedName,
        Description: achievement.Description
      });
    }
  }

  async AddPedagogicalEnd(iPedagogicalEnd) {
    if (iPedagogicalEnd) {
      // Save the pedagogical end choosen
      return await this.AddEvent(HistoryEventTypes.PEDAGOGICAL_END, {
        ID: iPedagogicalEnd.ID,
        Type: iPedagogicalEnd.Type,
        DisplayedName: iPedagogicalEnd.DisplayedName,
        Description: iPedagogicalEnd.Description
      });
    } else {
      return await this.AddEvent(HistoryEventTypes.PEDAGOGICAL_END, {
        PedagogicalEndName: 'No pedagogicalEndFound'
      });
    }
  }

  async AddPedagogicalRecommendations(iPedagogicalRecommendationsToDisplay) {
    for (const pedagogicalRecommendation of iPedagogicalRecommendationsToDisplay) {
      // Save the pedagogical Recommendations to display
      await this.AddEvent(HistoryEventTypes.PEDAGOGICAL_RECOMMENDATION, {
        ID: pedagogicalRecommendation.ID,
        Type: pedagogicalRecommendation.Type,
        DisplayedName: pedagogicalRecommendation.DisplayedName,
        Description: pedagogicalRecommendation.Description
      });
    }
  }

  async AddPedagogicalAdditions(iPedagogicalAdditionsToDisplay) {
    for (const pedagogicalAddition of iPedagogicalAdditionsToDisplay) {
      // Save the pedagogical additions to display
      await this.AddEvent(HistoryEventTypes.PEDAGOGICAL_ADDITION, {
        ID: pedagogicalAddition.ID,
        Type: pedagogicalAddition.Type,
        Description: pedagogicalAddition.Description
      });
    }
  }

  /**
   * AddNodeActivation function
   *
   * @param {Object} iActivationLink - Activation link object
   * @param {Object} iActivationLink.Source - Source of the link
   * @param {number|string} iActivationLink.Source.Node - Source node
   * @param {string} iActivationLink.Source.Port - Source port
   * @param {Object} iActivationLink.Target - Target of the link
   * @param {number|string} iActivationLink.Target.Node - Target node
   * @param {string} iActivationLink.Target.Port - Target port
   *
   */
  async AddNodeActivation(iActivationLink) {
    return await this.AddEvent(
      HistoryEventTypes.NODE_ACTIVATION,
      {
        NodeID: iActivationLink.Target.Node.ID,
        Type: iActivationLink.Target.Node.Type,
        Link: {
          Source: {
            NodeID: iActivationLink.Source.Node ? iActivationLink.Source.Node.ID : '',
            NodeType: iActivationLink.Source.Node ? iActivationLink.Source.Node.Type : '',
            PortName: iActivationLink.Source.Port ? iActivationLink.Source.Port.Name : ''
          },
          Target: {
            NodeID: iActivationLink.Target.Node.ID,
            NodeType: iActivationLink.Target.Node.Type,
            PortName: iActivationLink.Target.Port ? iActivationLink.Target.Port.Name : ''
          }
        }
      },
      false
    );
  }

  async AddBranchingDecisionResult(
    iNodeID,
    iChosenBranch,
    iCurrentSceneName,
    iCurrentSceneNodeID,
    iDatabaseID
  ) {
    return await this.AddEvent(HistoryEventTypes.BRANCHING_DECISION, {
      NodeID: iNodeID,
      ChosenBranch: iChosenBranch,
      CurrentSceneName: iCurrentSceneName,
      CurrentSceneNodeID: iCurrentSceneNodeID,
      DatabaseID: iDatabaseID
    });
  }

  async AddBranchingDecisionShortcut(
    iSourceNodeID,
    iTargetNodeID,
    iSourceDatabaseID,
    iTargetDatabaseID
  ) {
    return await this.AddEvent(HistoryEventTypes.BRANCHING_DECISION_SHORTCUT, {
      SourceNodeID: iSourceNodeID,
      TargetNodeID: iTargetNodeID,
      SourceDatabaseID: iSourceDatabaseID,
      TargetDatabaseID: iTargetDatabaseID
    });
  }

  async AddSceneActivation(
    iNodeID,
    iSceneName,
    iActNumber,
    iSceneNumber,
    iSummary,
    iContext,
    iObjectives,
    iShouldReplayPreviousVideo
  ) {
    return await this.AddEvent(HistoryEventTypes.SCENE_ACTIVATION, {
      NodeID: iNodeID,
      SceneName: iSceneName,
      ActNumber: iActNumber,
      SceneNumber: iSceneNumber,
      Summary: iSummary,
      Context: iContext,
      Objectives: iObjectives,
      ShouldReplayPreviousVideo: iShouldReplayPreviousVideo
    });
  }

  async AddUnlockedTrophyEvent(iNodeID, iTrophyID) {
    return await this.AddEvent(HistoryEventTypes.TROPHY, {
      NodeID: iNodeID,
      TrophyID: iTrophyID
    });
  }

  async AddGenerativeFeedback(iGenerativeFeedback) {
    await this.AddEvent(HistoryEventTypes.GENERATIVE_FEEDBACK, {
      GenerativeFeedback: iGenerativeFeedback
    });
  }

  async AddFeedbackRank(iRank) {
    await this.AddEvent(HistoryEventTypes.FEEDBACK_RANK, {
      Rank: iRank
    });
  }

  async AddDetailedFeedbacks(iDetailedFeedbacks) {
    await this.AddEvent(HistoryEventTypes.DETAILED_FEEDBACKS, {
      DetailedFeedbacks: iDetailedFeedbacks
    });
  }

  async AddCountersIncrementation(iIncrementedCounters) {
    await this.AddEvent(HistoryEventTypes.COUNTERS_INCREMENTATION, {
      IncrementedCounters: iIncrementedCounters
    });
  }

  async AddStopEvent(iStopNodeID, iStopType) {
    return await this.AddEvent(HistoryEventTypes.STOP, {
      NodeID: iStopNodeID,
      StopType: iStopType
    });
  }

  async AddActCompletionEvent(iActNodeID, iActName, iEvaluation, iUserActionsFeedbacks) {
    return await this.AddEvent(HistoryEventTypes.ACT_COMPLETION, {
      NodeID: iActNodeID,
      ActName: iActName,
      Evaluation: iEvaluation,
      UserActionsFeedbacks: iUserActionsFeedbacks
    });
  }

  async AddDetectionIssueEvent(iNodeID, iReason) {
    return await this.AddEvent(HistoryEventTypes.DETECTION_ISSUE, {
      NodeID: iNodeID,
      Reason: iReason
    });
  }

  async AddSceneEvaluationEvent(iEvaluatedSceneNodeID, iEvaluation, iUserActionsFeedbacks) {
    return await this.AddEvent(HistoryEventTypes.SCENE_EVALUATION, {
      EvaluatedSceneNodeID: iEvaluatedSceneNodeID,
      Evaluation: iEvaluation,
      UserActionsFeedbacks: iUserActionsFeedbacks,
      ActName: this.Graph.GetCurrentActName()
    });
  }

  GetOneEventBy(iCriteria) {
    return this.History.find((historyEvent) => {
      return Object.entries(iCriteria).every(([key, value]) => {
        const parts = key.split('.');
        let nestedValue = historyEvent;
        for (let i = 0; i < parts.length; i++) {
          if (nestedValue === null) {
            // covers both null and undefined
            nestedValue = undefined;
            break;
          }
          nestedValue = nestedValue[parts[i]];
        }
        return nestedValue === value;
      });
    });
  }

  GetAllEventsBy(iCriteria) {
    return this.History.filter((historyEvent) => {
      return Object.entries(iCriteria).every(([key, value]) => {
        const parts = key.split('.');
        let nestedValue = historyEvent;
        for (let i = 0; i < parts.length; i++) {
          if (nestedValue === null) {
            // covers both null and undefined
            nestedValue = undefined;
            break;
          }
          nestedValue = nestedValue[parts[i]];
        }
        return nestedValue === value;
      });
    });
  }

  GetDetectionIssueEvents(iReason = null) {
    return this.History.filter(
      (historyEvent) =>
        historyEvent.EventType === HistoryEventTypes.DETECTION_ISSUE &&
        (!iReason || historyEvent.Content.Reason === iReason)
    );
  }

  GetActCompletionEvents() {
    return this.History.filter(
      (historyEvent) => historyEvent.EventType === HistoryEventTypes.ACT_COMPLETION
    );
  }

  GetUserActions() {
    return this.History.filter(
      (historyEvent) => historyEvent.EventType === HistoryEventTypes.USER_ACTION
    );
  }

  GetUserActionsFeedbacks() {
    return this.History.filter(
      (historyEvent) => historyEvent.EventType === HistoryEventTypes.USER_ACTION_FEEDBACK
    );
  }

  GetNarrativeEnd() {
    // Send choosen narrative end
    let narrativeEnd = null;
    this.History.forEach((historyEvent) => {
      if (historyEvent.EventType === HistoryEventTypes.NARRATIVE_END) {
        narrativeEnd = historyEvent;
      }
    });

    return narrativeEnd;
  }

  GetTrophiesEvents() {
    return this.History.filter((event) => event.EventType === HistoryEventTypes.TROPHY);
  }

  GetConversation() {
    let conversation = [];
    this.History.forEach((historyEvent) => {
      if (
        historyEvent.EventType === HistoryEventTypes.USER_SPEECH ||
        historyEvent.EventType === HistoryEventTypes.BOT_SPEECH
      ) {
        conversation.push(historyEvent);
      }
    });
    return conversation;
  }

  GetConversationAsText(iLastRepliesCount = 0, iExcludeLastUserSpeech = false) {
    let conversationPieces = [];
    const conversationData = this.GetConversation();

    // Determines if the last user speech should be excluded
    let excludeLast =
      iExcludeLastUserSpeech &&
      conversationData[conversationData.length - 1].EventType === HistoryEventTypes.USER_SPEECH;

    let repliesCount = 0;
    for (let i = conversationData.length - 1; i >= 0; i--) {
      // If the last user speech should be excluded, skip it
      if (excludeLast && i === conversationData.length - 1) {
        continue;
      }

      let historyEvent = conversationData[i];
      let piece = `${historyEvent.Content.Character} : "${historyEvent.Content.Speech}"`;
      conversationPieces.push(piece);
      repliesCount++;

      if (iLastRepliesCount > 0 && repliesCount === iLastRepliesCount) {
        break;
      }
    }

    // Reverse the order of the conversation pieces to get the correct order
    conversationPieces.reverse();

    let conversation = conversationPieces.join('\n');
    return conversation;
  }

  GetConversationForBranchingDecision(iNodeID, iExcludeLastUserSpeech = false) {
    let conversationPieces = [];
    const conversationData = this.GetConversation();
    let excludeLast =
      iExcludeLastUserSpeech &&
      conversationData[conversationData.length - 1].EventType === HistoryEventTypes.USER_SPEECH;

    // Iterate through the conversation data in reverse order
    for (let i = conversationData.length - 1; i >= 0; i--) {
      let historyEvent = conversationData[i];

      // Check if the event is relevant to the current branching decision
      if (
        historyEvent.Content.NodeID === iNodeID ||
        historyEvent.EventType === HistoryEventTypes.BOT_SPEECH
      ) {
        if (historyEvent.EventType === HistoryEventTypes.USER_SPEECH) {
          // Add user speech to the conversation, unless it's the last one and we're excluding it
          if (!(excludeLast && i === conversationData.length - 1)) {
            let piece = `${historyEvent.Content.Character} : "${
              historyEvent.Content.BeautifiedSpeech || historyEvent.Content.Speech
            }"`;
            conversationPieces.push(piece);
          }
        } else if (historyEvent.EventType === HistoryEventTypes.BOT_SPEECH) {
          // Add bot speech to the conversation
          let piece = `${historyEvent.Content.Character} : "${historyEvent.Content.Speech}"`;
          conversationPieces.push(piece);
        }
      } else {
        // Stop if we've moved past the relevant conversation for this branching decision
        break;
      }
    }

    // Reverse the order of the conversation pieces to get chronological order
    conversationPieces.reverse();

    // Join the conversation pieces into a single string
    let conversation = conversationPieces.join('\n');
    return conversation;
  }

  GetUserSpeechByBranchingDecisionDatabaseID(iBranchingDecisionDatabaseID) {
    return this.History.find(
      (event) =>
        event.EventType === HistoryEventTypes.USER_SPEECH &&
        event.Content.BranchingDecisionDatabaseID === iBranchingDecisionDatabaseID
    );
  }

  GetStopEvent() {
    return this.History.find((event) => event.EventType === HistoryEventTypes.STOP);
  }

  GetVideoEventBeforeUserActionFeedback(iUserActionFeedbackNodeID) {
    let videoEventBeforeUserActionFeedback = null;

    for (let i = 0; i < this.History.length; i++) {
      if (this.History[i].EventType === HistoryEventTypes.BOT_SPEECH) {
        videoEventBeforeUserActionFeedback = this.History[i];
      }

      if (
        this.History[i].EventType === HistoryEventTypes.USER_ACTION_FEEDBACK &&
        this.History[i].Content.NodeID === iUserActionFeedbackNodeID
      ) {
        return videoEventBeforeUserActionFeedback;
      }
    }

    return null;
  }

  GetPreviouslyActivatedNodeOfType(iStartEventID, iNodeTypes) {
    let startEvent = null;
    let previousNodeIDToCheck = '';

    for (let i = this.History.length - 1; i >= 0; i--) {
      let historyEvent = this.History[i];

      if (historyEvent.EventType !== HistoryEventTypes.NODE_ACTIVATION) {
        continue;
      }

      // Search for the start node activation in the history
      if (!startEvent) {
        if (iStartEventID === historyEvent.EventID) {
          startEvent = historyEvent;
          previousNodeIDToCheck = historyEvent.Content.Link.Source.NodeID;
        }
        continue;
      }

      // If we found the previously activated node to check, and type matches, return it
      if (historyEvent.Content.NodeID !== previousNodeIDToCheck) {
        continue;
      } else {
        // Check if the node is of the specified type
        if (iNodeTypes.includes(historyEvent.Content.Type)) {
          log.debug(
            'ExerciseSessionHistory.GetPreviouslyActivatedNodeOfType: Found node ID ' +
              historyEvent.Content.NodeID +
              ' of type ' +
              historyEvent.Content.Type +
              '\nFrom event ID ' +
              iStartEventID +
              ' and types ' +
              iNodeTypes
          );
          return this.Graph.GetNodeByID(historyEvent.Content.NodeID);
        } else {
          // If the node is not of the specified type, check the previously activated one
          previousNodeIDToCheck = historyEvent.Content.Link.Source.NodeID;
        }
      }
    }

    log.error(
      'ExerciseSessionHistory.GetPreviouslyActivatedNodeOfType: No previously activated node of the specified type found from event ID ' +
        iStartEventID +
        ' and types ' +
        iNodeTypes
    );
    return null;
  }

  UpdateEventContent(iEventID, iContent) {
    // Update local version of history event
    let eventToUpdate = this.History.find((event) => event.EventID === iEventID);
    eventToUpdate.Content = iContent;

    // Update history event in DynamoDB
    window.sdk.exerciseSession().updateHistoryEvent(eventToUpdate);
  }

  UpdateUserSpeech(
    iEventID,
    iNodeID,
    iAnalysisTaskID,
    iSpeech,
    iBranchingDecisionDatabaseID,
    iSpeechSource,
    iBeautifiedSpeech
  ) {
    this.UpdateEventContent(iEventID, {
      NodeID: iNodeID,
      Actor: 'User',
      Character: this.UserName,
      AnalysisTaskID: iAnalysisTaskID,
      Speech: iSpeech,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID,
      SpeechSource: iSpeechSource,
      BeautifiedSpeech: iBeautifiedSpeech
    });
  }

  Print() {
    log.debug('- History:', this.History);
  }
}
